export const allEvents = (data) => {
  // return dummyData.map((meeting, index) => {
  return data.map((meeting, index) => {
    // Calculate the end time by adding the duration to the start time
    const startTime = new Date(meeting.startTime);
    const endTime = new Date(meeting.endTime);
    // const endTime = new Date(startTime.getTime() + meeting.duration * 60000);

    return {
      id: index + 1,
      title: meeting?.name, //left
      start: startTime,
      end: endTime,
      // uuid: meeting?.uuid,
      // hostId: meeting?.hostId,
      // duration: meeting?.duration,
      timezone: meeting?.timeZone,
      // created_at: new Date(meeting.created_at),
      join_url: meeting?.join_url,
      name: meeting?.name,
      // meetingStatus: meeting?.meetingStatus,
      meet_id: meeting?.meetingId,
      hrMeetingStatus: meeting?.expertMeetingStatus,
    };
  });
};

const dummyData = [
  {
    _id: "67289b80e1a3162206f2b878",
    expertId: "expert_india@bzeero.com",
    candidateId: "raurugegoihe-9804@yopmail.com",
    startTime: "2024-12-28 02:30 AM",
    endTime: "2024-12-28 03:30 AM",
    date: "05-11-2024",
    join_url:
      "https://us06web.zoom.us/j/83823285382?pwd=rA4DDeDfFqQi8a5f9qJXos5loJvPZb.1",
    meetingId: 83823285382,
    passcode: "426812",
    name: "AKS Test",
    timeZone: "Africa/Bangui",
    expertMeetingStatus: "Pending",
    reason: null,
    meetingNote: null,
  },
];
