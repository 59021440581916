import React, { useState, useEffect, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import EventComponent from "../EventComponent/EventComponent";
import CustomToolbar from "../CustomToolbar/CustomToolbar";
import Tooltip from "../Tooltip/Tooltip";
import CustomDateHeader from "../CustomDateHeader/CustomDateHeader";
import CustomHeader from "../CustomHeader/CustomHeader";
import CustomTimeGutterWrapper from "../CustomTimeGutterWrapper/CustomTimeGutterWrapper";
import { calculateDateRange } from "../DateUtils/DateUtils";
import "./MyCalendar.scss";
import { getAllEvents } from "../../../Apis/calendarEvents";
import { allEvents } from "../../../commonFunctions/allEvents";
import LoadingOverlay from "../../Common/LoadingOverlay";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialView = searchParams.get("view") || "month"; // Get view from URL or default to 'week'
  const [view, setView] = useState(initialView);
  const viewRef = useRef(view);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [events, setEvents] = useState([]);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const tooltipRef = useRef(null);
  const [timeZone] = useState("GMT+5:30");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const checkForDeclinedEvents = () => {
    const eventElements = document.querySelectorAll(".rbc-event");

    eventElements.forEach((eventElement) => {
      const declinedElement = eventElement.querySelector(".declined");

      if (declinedElement) {
        // Apply your custom styling or logic for declined events
        eventElement.style.backgroundColor = "#E8EBF080";
      }
    });
  };

  const loadEvents = async (dateRange) => {
    setLoading(true);
    try {
      const res = await getAllEvents(dateRange);
      const data = allEvents(res);
      setEvents([...data]);
    } catch (err) {
      console.log("loadEvents error =>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleViewChange = (newView) => {
    console.log("new view =>", newView);
    setView(newView);
    viewRef.current = newView; // Update the ref to the new view

    // Apply the custom CSS for the day view
    const daySlots = document.querySelectorAll(
      ".rbc-day-slot.rbc-time-column.rbc-now.rbc-today"
    );
    daySlots.forEach((daySlot) => {
      const timeslotGroups = daySlot.querySelectorAll(".rbc-timeslot-group");
      if (newView === "day") {
        timeslotGroups.forEach((group) => {
          group.style.backgroundColor = "#fff";
        });
      } else {
        timeslotGroups.forEach((group) => {
          group.style.backgroundColor = "";
        });
      }
    });
  };

  const handleRangeChange = async (range) => {
    console.log("handleRangeChange==>", range);
    console.log("handleRangeChange==>2==>", viewRef.current);
    // Create a promise that resolves after 200 milliseconds
    await new Promise((res) => setTimeout(res, 400));
    const currentView = viewRef.current; // Use the ref to get the latest view
    let newRange;
    if (currentView.toLocaleLowerCase() === "month") {
      newRange = { startDate: range.start, endDate: range.end };
    } else if (
      currentView.toLocaleLowerCase() === "week" ||
      currentView.toLocaleLowerCase() === "day"
    ) {
      newRange = { startDate: range[0], endDate: range[range?.length - 1] };
    } else {
      newRange = calculateDateRange(new Date(), currentView);
    }

    setDateRange(newRange);
    loadEvents(newRange);
  };

  const handleEventInteraction = (event, e) => {
    const eventRect = e.currentTarget.getBoundingClientRect();
    const tooltipWidth = 150; // Assume a fixed width for tooltip for calculation
    const tooltipHeight = 100; // Assume a fixed height for tooltip for calculation

    // Check if the start date falls on a Saturday
    const eventStartDate = new Date(event.start);
    const isSaturday = eventStartDate.getDay() === 6; // 6 represents Saturday in JavaScript Date

    // console.log(`Event Start Date: ${eventStartDate.toDateString()}`);
    // console.log(`Is Saturday: ${check}`);

    let tooltipLeft = isSaturday
      ? eventRect.left + window.scrollX - tooltipWidth - 50
      : eventRect.left + window.scrollX + eventRect.width / 2; // Position tooltip 100px to the left
    let tooltipTop = eventRect.top + window.scrollY - tooltipHeight - 10;

    // Adjust if tooltip goes beyond viewport on the right side
    if (tooltipLeft + tooltipWidth > window.innerWidth) {
      tooltipLeft = window.innerWidth - tooltipWidth - 10; // Adjust to stay within viewport
    }

    // Adjust if tooltip goes beyond viewport on the left side
    if (tooltipLeft < 0) {
      tooltipLeft = 10; // Adjust to stay within viewport
    }

    // Adjust if tooltip goes beyond viewport at the top
    if (tooltipTop < 0) {
      tooltipTop = eventRect.bottom + window.scrollY + 10; // Position below the event
    }

    setHoveredEvent(event);
    setTooltipPosition({
      top: tooltipTop,
      left: tooltipLeft,
    });
  };

  const handleTooltipMouseEnter = () => {
    setIsTooltipHovered(true);
  };

  const handleMouseLeave = () => {
    // Delay hiding the tooltip to allow for mouse enter on tooltip
    setTimeout(() => {
      if (!isTooltipHovered) {
        setHoveredEvent(null);
      }
    }, 200);
  };

  const handleTooltipMouseLeave = () => {
    setIsTooltipHovered(false);
    setHoveredEvent(null);
  };

  // join button click
  const handleJoinMetting = (event, url) => {
    if (event?.hrMeetingStatus?.toLowerCase() === "declined") {
      // Stop propagation to prevent further handling
      return;
    }
    // Extract start date and current date
    // const startDate = new Date(event.start);
    // const currentDate = new Date();

    // Calculate the time one hour after the event start time
    // const startTimePlusOneHour = new Date(startDate.getTime() + 60 * 60 * 1000);

    // Check if the current time is within one hour of the event start time
    // if (currentDate <= startTimePlusOneHour) {
    // Construct the query parameters

    const queryParams = new URLSearchParams({
      start: event.start,
      end: event.end,
      timezone: event.timezone,
      title: event.title,
      join_url: event.join_url,
      meet_id: event.meet_id,
    }).toString();

    // Navigate to /meetingDetails with query parameters
    navigate(`${url}?${queryParams}`);
    // } else {
    // Show a toast message if the event has already passed
    //   toast.error("Sorry, the event has already passed", {
    //     autoClose: 2000, // Display duration in milliseconds (2 seconds)
    //   });
    // }
  };

  useEffect(() => {
    const dateRange = calculateDateRange(new Date(), view);
    loadEvents(dateRange);
  }, []);

  useEffect(() => {
    // Update URL when view changes
    const params = new URLSearchParams(searchParams);
    params.set("view", view);
    setSearchParams(params, { replace: true });
  }, [view, setSearchParams, searchParams]);

  // Function to check for declined events on load
  useEffect(() => {
    // Call the function once the component is mounted
    checkForDeclinedEvents();
  }, [events]); // Empty dependency array ensures this effect runs only once after mount

  return (
    <>
      {loading && <LoadingOverlay loading={loading} />}
      <div className="calendar-container">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["month", "week", "day"]}
          view={view}
          onView={handleViewChange}
          onRangeChange={handleRangeChange}
          step={60} // Set each time slot to 60 minutes
          timeslots={1} // Combine time slots into one per row
          style={{ height: 750 }}
          components={{
            event: (props) => (
              <EventComponent
                {...props}
                onHover={(event, e) => handleEventInteraction(event, e)}
                onLeave={handleMouseLeave}
                onClick={(event) => handleJoinMetting(event, "/meetingDetails")}
              />
            ),
            toolbar: CustomToolbar,
            timeGutterHeader: () => (
              <CustomTimeGutterWrapper timeZone={timeZone} />
            ),
            day: {
              header: CustomDateHeader,
            },
            week: {
              header: (props) => <CustomHeader {...props} view="week" />,
            },
            month: {
              header: (props) => <CustomHeader {...props} view="month" />,
            },
          }}
        />
        <Tooltip
          hoveredEvent={hoveredEvent}
          tooltipPosition={tooltipPosition}
          handleTooltipMouseEnter={handleTooltipMouseEnter}
          handleTooltipMouseLeave={handleTooltipMouseLeave}
          tooltipRef={tooltipRef}
          handleJoinMetting={handleJoinMetting}
        />
      </div>
    </>
  );
};

export default MyCalendar;
